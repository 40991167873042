import React from 'react';
import Animation from './canvas.js';
// import Canvas from './canvas.js';
import './App.css';
import copy from './copy.json';
import { copyCtaContainer, nudgeRow } from './styleObjects.js';
import smallProfile from './small-profile.png';
import midProfile from './icon192.png';
import services2 from './services-consultation.png';
import services3 from './services-smoothies.png';
import bioCollage from './bio-collage.png';
import bioVideo from './beach.mp4';
import phil1 from './phil1.png';
import phil2 from './phil2.png';
import phil3 from './phil3.png';
import phil4 from './phil4.png';
import step1 from './step1.png';
import step2 from './step2.png';
import step3 from './step3.png';
import nudge from './nudge.png';
import chatWindow from './chatwindow.svg';
import collage1 from './collage1.png';
import headercollage from './headercollage.png';
import seaworthyLogo from './seaworthy.png';
import voxicon from './voice_icon.png';

import seed1 from './seeds/seed10.svg';
import seed2 from './seeds/seed2.svg';
import seed3 from './seeds/seed3.svg';
import seed4 from './seeds/seed4.svg';
import seed5 from './seeds/seed5.svg';
import seed6 from './seeds/seed6.svg';
import seed7 from './seeds/seed7.svg';
import seed8 from './seeds/seed8.svg';
import seed9 from './seeds/seed9.svg';

const seeds = [seed1,seed2,seed3,seed4,seed5,seed6,seed7,seed8,seed9];

const list = function(parent){
  return parent.map((line) => {
    return <p>{line}</p>
  })
};

const lilist = function(parent) {
  return parent.map((item) => {
    return <li>{item}</li>
  })
};

const header = (
  <div className="header">
    <div>
      <img src={midProfile} alt="Carol"></img>
      <h1>{copy.header.title}</h1>
    </div>
    <h4>{copy.header.subtitle}</h4>
  </div>
);

const opener = (
  <div className="opener">
    <div>
      <h2>{copy.opener.tagline}</h2>
      <h3>{copy.opener.body}</h3>
    </div>
    <figure><img src={headercollage} alt="collage placehodler"></img></figure>
    <div>
      <figure>
        <img src={chatWindow} alt="Phone chat window with Carol encouraging you to keep on track with your movement goal"></img>
      </figure>
      <a target="new" href="https://calendly.com/comfort-health/discovery-call">
      {/* <a target="new" href="https://docs.google.com/forms/d/e/1FAIpQLSePGvnMESG9R9KalXK632JyjYr5--Lg7w1uwy5DbCwLPJy4GA/viewform"> */}
        <button>
          {copy.opener.cta}
        </button>
      </a>
    </div>
    <div>
      <h3>{copy.opener.subhead}</h3>
      <ul>
        {lilist(copy.opener.subbody)} 
      </ul>
    </div>
  </div>
);

const services = (
  <div className="services">
    <h2>{copy.services.title}</h2>
    <div>
      <h3>{copy.services.one.title}</h3>
      {list(copy.services.one.body)}
      <div style={copyCtaContainer}>
        <a href={copy.services.one.link}>
          <button class="">{copy.services.one.linkText}</button>
        </a>
      </div>
    </div>
    <figure><img src={collage1} alt="A free introductory call"></img></figure>
    <figure><img src={services2} alt="Consulting sessions"></img></figure>
    <div>
      <h3>{copy.services.two.title}</h3>
      <p>{list(copy.services.two.body)}</p>
      <p>{copy.services.two.final}</p>
    </div>
    <div>
      <h3>{copy.services.three.title.mainline}</h3>
      <div style={nudgeRow}>
        <p>{copy.services.three.title.subline}</p>
        <img src={nudge} alt="nudge app on ios and android"></img>
      </div>
      {list(copy.services.three.body)}
      <div className={`exempt`} style={copyCtaContainer}>
        <p>{copy.services.three.linkPre}</p>
        <a href={copy.services.three.link}>
          <button>Go to Nudge</button>
        </a>
        <p>{copy.services.three.linkText}</p>
      </div>

    </div>
    <figure><img src={services3} alt="Daily practice through lifestyle change"></img></figure>
  </div>
);

const bio = (
  <div className="bio">
    <video src={bioVideo} autoPlay="true" loop></video>
    <h2>{copy.bio.title}</h2>
    <div>
      {list(copy.bio.body)}
    </div>
    <figure><img src={bioCollage} alt="Carol hiking in Alaska"></img></figure>
  </div>
);

const philosophy = (
  <div className="philosophy">
    <h2>{copy.philosophy.title}</h2>
    <div>
      <p>{copy.philosophy.body[0]}</p>
      <img src={phil1} alt="Philosophy"></img>
      <img src={phil2} alt="Philosophy"></img>
      <p>{copy.philosophy.body[1]}</p>
      <p>{copy.philosophy.body[2]}</p>
      <img src={phil3} alt="Philosophy"></img>
      <img src={phil4} alt="Philosophy"></img>
      <p>{copy.philosophy.body[3]}</p>
    </div>
  </div>
);

const practice = (
  <div className="practice">
    <h2>{copy.practice.title}</h2>
    <Animation seeds={seeds}></Animation>
    <div>
      <img src={step1} alt="Sprouting"></img>
      <p>{copy.practice.body[0]}</p>
    </div>
    <div>
      <img src={step2} alt="Sprouting"></img>
      <p>{copy.practice.body[1]}</p>
    </div>
    <div>
      <img src={step3} alt="Sprouting"></img>
      <p>{copy.practice.body[2]}</p>
    </div>
  </div>
);

const cta = (
  <div className="cta">
    <div>
      <img src={smallProfile} alt="Carol"></img>
      <div>
        <h3>{copy.cta.tagline}</h3>
        <p>{copy.cta.body}</p>
      </div>
    </div>
    <div>
      <button><a target="new" href="https://calendly.com/comfort-health/discovery-call">{copy.cta.cta}</a></button>
      {/* <button><a target="new" href="https://docs.google.com/forms/d/e/1FAIpQLSePGvnMESG9R9KalXK632JyjYr5--Lg7w1uwy5DbCwLPJy4GA/viewform">{copy.cta.cta}</a></button> */}
    </div>
  </div>
);

const podcast = (
  <div className="podcast">
    <div>
      <img src={voxicon} alt="listen" className="icon"></img>
      <h3>{copy.podcast.title}</h3>
      <p>{copy.podcast.body}</p>
    </div>
    <div>
      <a href={copy.podcast.episode.href} target="new">
        <img src={seaworthyLogo} alt="Seaworthy Medicine" className="logo"></img>
        <h4>{copy.podcast.episode.number}</h4>
        <div class="arrow-right"></div>
        <h3>{copy.podcast.episode.title}</h3>
      </a>
    </div>
  </div>
)

const testimonials = (
  <div className="testimonials">
    <h2>{copy.testimonials.title}</h2>
    <div>
      {list(copy.testimonials.texts.one.body)}
      <p>{copy.testimonials.texts.one.signature}</p>
    </div>
    <div>
      {list(copy.testimonials.texts.two.body)}
      <p>{copy.testimonials.texts.two.signature}</p>
    </div>
    <div>
      {list(copy.testimonials.texts.three.body)}
      <p>{copy.testimonials.texts.three.signature}</p>
    </div>
    <div>
      {list(copy.testimonials.texts.four.body)}
      <p>{copy.testimonials.texts.four.signature}</p>
    </div>
  </div>
);

const footer = (
  <div className="footer">
    <div>
      <h3>{copy.footer[0]}</h3>
      {list(copy.footer)}</div>
    {/* <div>{list(copy.nav)}</div> */}
  </div>
);


function App() {
  return (
    <div className="container">
      <header className="page-header">
        {header}
      </header>
      {opener}
      {services}
      {bio}
      {philosophy}
      {practice}
      {cta}
      {podcast}
      {testimonials}
      {footer}
    </div>
  );
}

// //example syntax
// <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>

export default App;
