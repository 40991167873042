export const copyCtaContainer = {
  textAlign: "center",
  border: "1px solid #eee",
  padding: "1rem",
  borderRadius: '8px',
  color: '#333',
  textStyle: 'none',
}

export const nudgeRow ={
  display: 'flex',
}