import React from 'react';

class Animation extends React.Component {
  constructor(props) {
    super(props);
    const seeds = this.props.seeds.map((seed) => {
      let img = new Image();
      img.src = seed;
      return img;
    });
    this.state = { seeds: seeds, angle: 0 };
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }
  
  componentDidMount() {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }
  
  componentWillUnmount() {
    cancelAnimationFrame(this.rAF);
  }
  
  updateAnimationState() {
    this.setState(prevState => ({ 
      angle: prevState.angle > window.innerWidth + 100 ? 0 : prevState.angle + 1
    }));
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }
  
  render() {
    return <Canvas seeds={ this.state.seeds } angle={this.state.angle} />
  }
}

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }
  
  componentDidMount() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingIsEnabled = false;
    const dpr = window.devicePixelRatio;
    canvas.width = window.innerWidth;
    canvas.height = 200;
    canvas.style.width  = canvas.width/2 + "px";
    canvas.style.height = canvas.height/2 + "px";
    ctx.scale(dpr,dpr);
    // ctx.save();
    // ctx.beginPath();
    // ctx.clearRect(0, 0, width, height);
    // ctx.translate(width/2, height/2 );
    // ctx.fillStyle = '#4397AC';
    // ctx.fillRect(-width/4, -height/4, width/2, height/2);
    // ctx.restore();
  }

  componentDidUpdate() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const x = this.props.angle;

    this.props.seeds.forEach((seed) => {
      let fx = this.props.seeds.findIndex((element) => {return seed.src === element.src });
      let dx = (fx * 100 + x) + (-1 * fx^2/10) + (- canvas.width/1.7);
      let dy = Math.sin(fx * x / 100 / 10 + fx) * 25 -fx*2 + 50;
      // ctx.save();
      // ctx.rotate(fx);
      ctx.drawImage(seed,dx,dy);
      ctx.restore();
    }, x)
  }
  
  render() {
    return <canvas id="animCanvas" ref={this.canvasRef}></canvas>;
  }
}

export default Animation;